import React, { useEffect, useRef, useState } from "react"
import Seo from "../../seo"
import "@fontsource/noto-sans"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "../../../css/swiftcloud_global.css"
import "../../Layout/menu.css"
import "../../../css/edoc_front.css"
import { Form } from "react-bootstrap"
import { FAVICON_ICON, BRANDING_DOC_IMAGE, BRANDING_COLOR } from "../../../services/globalStaticData"
import SignatureCanvas from "react-signature-canvas"
import { isBrowser } from "./Service/docHelper"
import AxiosInstance from "../../../services/axiosInstance"

const Preview = ({ userName, fileUrl }) => {
  const isBrowser = () => typeof window !== "undefined"
  const signCanvas = useRef({})
  const clearSignature = () => signCanvas.current.clear()
  const [loadDocDetails, setLoadDocDetails] = useState(true),
    [allDetails, setAllDtails] = useState(""),
    [ownerId, setOwnerId] = useState(""),
    [driveAllId, setDriveAllId] = useState(""),
    [content, setContent] = useState(""),
    [htmlContent, setHtmlContent] = useState(""),
    [printOff, setPrintOff] = useState(""),
    [saveLoading, setSaveLoading] = useState(""),
    [brandingImage, setBrandingImage] = useState(BRANDING_DOC_IMAGE),
    [brandingFavicon, setBrandingFavicon] = useState(FAVICON_ICON),
    [brandingColor, setBrandingColor] = useState(BRANDING_COLOR)

  const getDocData = async () => {
    await AxiosInstance.get(`/${userName}/doc/${fileUrl}`, {}).then(response => {
      fillTheData(response.data.details)
    })
  }

  const fillTheData = details => {
    setAllDtails(details)
    setContent(details.content)
    setOwnerId(details.driveAll.ownerID)
    setDriveAllId(details.driveAll.id)
    setHtmlContent(details.content)
    setPrintOff(details.driveForm.moreOptions.doc_print)
  }
  useEffect(() => {
    if (loadDocDetails) {
      setLoadDocDetails(false)
      getDocData()
      setBrandingImage(
        isBrowser()
          ? window.localStorage.getItem("brandingImage")
            ? `${process.env.GATSBY_WRANGLER_URL}` + window.localStorage.getItem("brandingImage")
            : BRANDING_DOC_IMAGE
          : BRANDING_DOC_IMAGE
      )
      setBrandingFavicon(
        isBrowser()
          ? window.localStorage.getItem("brnadingImageFavicon")
            ? `${process.env.GATSBY_WRANGLER_URL}` + window.localStorage.getItem("brnadingImageFavicon")
            : FAVICON_ICON
          : FAVICON_ICON
      )
      setBrandingColor(isBrowser() ? window.localStorage.getItem("brandingBackground") : BRANDING_COLOR)
    }
  })

  const submitForm = e => {
    e.preventDefault()
    setSaveLoading("disabled")
    const formData = new FormData(e.target)
    const json = {}
    Array.from(formData.entries()).forEach(([key, value]) => {
      json[key] = value
    })
    return new Promise(async () => {
      await AxiosInstance.post(`/drive/form-handling/add`, json, {
        headers: {},
      })
        .then(function (response) {
          setSaveLoading("")
          console.log(response.data)
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            console.log(error.response.data)
          } else if (error.response.data) {
            console.log(error.response.data.detail)
          }
        })
    })
  }
  return (
    <React.Fragment>
      <Seo title={userName} />
      <body style={{ backgroundColor: brandingColor }}>
        <header>
          <div className="container">
            <div className="row align-items-center py-3">
              <div className="col-sm-6 text-center text-sm-start">
                <a href="#">
                  <img src={brandingImage} alt="" className="companylogo" />
                </a>
              </div>
              <div className="col-sm-6 text-center text-sm-end mt-3 mt-sm-0">
                <button
                  type="button"
                  className="btn btnSignIn my-1"
                  data-bs-toggle="modal"
                  data-bs-target="#signInModal"
                >
                  <i className="bi bi-telephone-fill" /> +1 987-654-3210
                </button>
                <button
                  type="button"
                  className="btn btnSignIn my-1"
                  data-bs-toggle="modal"
                  data-bs-target="#signInModal"
                >
                  <i className="bi bi-box-arrow-in-right" /> Sign In
                </button>
              </div>
            </div>
          </div>
        </header>
        <section className="main_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="signableDocContainer my-3">
                  <Form onSubmit={submitForm} id={"docForm"}>
                    <Form.Control type="hidden" name="formId" defaultValue={ownerId + `F` + driveAllId} />

                    <Form.Control type="hidden" name="referer" defaultValue={`/drive/form-handling/add`} />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: htmlContent,
                      }}
                    />
                    <div className="text-center pt-3">
                      {(printOff === "add-sign-send" || printOff === "show-print-only") && (
                        <button className="btn btn-default btn-lg my-1">
                          <i className="bi bi-printer" /> Print
                        </button>
                      )}
                      {(printOff === "off" || printOff === "add-sign-send") && (
                        <button
                          className={`btn btn-secondary btn-lg my-1 ${saveLoading}`}
                          id="swift_sign_email_verification"
                        >
                          <i className="bi bi-check2" /> Sign and Send &nbsp;
                          <i className="bi bi-send-fill" /> &nbsp;
                          <i className={saveLoading !== "" ? "spinner-border spinner-border-sm" : ""} />
                        </button>
                      )}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <div className="top-footer">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-4 col-sm-12 top-copyrights text-lg-start text-center">
                  &copy; 2023{" "}
                  <a href="https://SwiftCloud.AI" target="_blank">
                    SwiftCloud&trade;
                  </a>
                  &nbsp;&nbsp; |&nbsp;&nbsp;
                  <a href="https://swiftcrm.com/legal/terms" className="footer-menu" target="_blank">
                    Terms of Use
                  </a>
                  &nbsp;&nbsp; |&nbsp;&nbsp;
                  <a href="https://swiftcrm.com/legal/privacy" className="footer-menu" target="_blank">
                    Privacy
                  </a>
                  &nbsp;&nbsp; |&nbsp;&nbsp;
                  <a href="https://swiftcrm.com/accessibility" className="footer-menu" target="_blank">
                    Accessibility
                  </a>
                  &nbsp;&nbsp;
                </div>
                <div className="col-lg-4 col-md-12 text-center poweredby">
                  <span>Powered by</span>
                  <a href="https://swiftcrm.com/software/electronic-signature" target="_blank">
                    <img src="images/swiftcloud_footer_logo_punched.png" />
                  </a>
                </div>
                <div className="col-lg-4 col-sm-12  text-lg-end text-center footer-language">
                  <div className="btn-group dropup language-switcher-container">
                    <button
                      className="btn btn-outline-secondary dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="glyphicon bfh-flag-US" />
                      English
                    </button>
                    <ul className="dropdown-menu selectLanuage">
                      <li>
                        <a href="/is/change-locale/af" className="dropdown-item" role="menuitem">
                          {" "}
                          <i className="glyphicon bfh-flag-AF" />
                          Afrikaans{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/sq"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-SQ" />
                          Albanian{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ar-dz"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-AR-DZ" />
                          Arabic (algeria){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ar-bh"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-AR-BH" />
                          Arabic (bahrain){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ar-eg"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-AR-EG" />
                          Arabic (egypt){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ar-iq"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-AR-IQ" />
                          Arabic (iraq){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ar-jo"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-AR-JO" />
                          Arabic (jordan){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ar-lb"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-AR-LB" />
                          Arabic (lebanon){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ar-ly"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-AR-LY" />
                          Arabic (libya){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ar-ma"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-AR-MA" />
                          Arabic (morocco){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ar-om"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-AR-OM" />
                          Arabic (oman){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ar-qa"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-AR-QA" />
                          Arabic (qatar){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ar-sa"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-AR-SA" />
                          Arabic (saudi arabia){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ar-sy"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-AR-SY" />
                          Arabic (syria){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ar-tn"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-AR-TN" />
                          Arabic (tunisia){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ar-ae"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-AR-AE" />
                          Arabic (u.a.e.){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ar-ye"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-AR-YE" />
                          Arabic (yemen){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/eu"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-EU" />
                          Basque{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/be"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-BE" />
                          Belarusian{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/bs"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-BS" />
                          Bosnian{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/bg"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-BG" />
                          Bulgarian{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ca"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-CA" />
                          Catalan{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/zh-hk"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ZH-HK" />
                          Chinese (hong kong){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/zh-cn"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ZH-CN" />
                          Chinese (prc){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/zh-sg"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ZH-SG" />
                          Chinese (singapore){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/zh-tw"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ZH-TW" />
                          Chinese (taiwan){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/hr"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-HR" />
                          Croatian{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/cs"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-CS" />
                          Czech{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/da"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-DA" />
                          Danish{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/nl-be"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-NL-BE" />
                          Dutch (belgium){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/nl"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-NL" />
                          Dutch (standard){" "}
                        </a>
                      </li>
                      <li>
                        <a href="/is/change-locale" className="dropdown-item lang-dropdown" role="menuitem">
                          {" "}
                          <i className="glyphicon bfh-flag-EN" />
                          English{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/en-au"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-EN-AU" />
                          English (australia){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/en-bz"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-EN-BZ" />
                          English (belize){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/en-ca"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-EN-CA" />
                          English (canada){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/en-ie"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-EN-IE" />
                          English (ireland){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/en-jm"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-EN-JM" />
                          English (jamaica){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/en-nz"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-EN-NZ" />
                          English (new zealand){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/en-za"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-EN-ZA" />
                          English (south africa){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/en-tt"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-EN-TT" />
                          English (trinidad){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/en-gb"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-EN-GB" />
                          English (united kingdom){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/en-us"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-EN-US" />
                          English (united states){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/et"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ET" />
                          Estonian{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/fo"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-FO" />
                          Faeroese{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/fa"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-FA" />
                          Farsi{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/fi"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-FI" />
                          Finnish{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/fr-be"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-FR-BE" />
                          French (belgium){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/fr-ca"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-FR-CA" />
                          French (canada){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/fr-lu"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-FR-LU" />
                          French (luxembourg){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/fr"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-FR" />
                          French (standard){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/fr-ch"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-FR-CH" />
                          French (switzerland){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/gd"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-GD" />
                          Gaelic (scotland){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/de-at"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-DE-AT" />
                          German (austria){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/de-li"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-DE-LI" />
                          German (liechtenstein){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/de-lu"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-DE-LU" />
                          German (luxembourg){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/de"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-DE" />
                          German (standard){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/de-ch"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-DE-CH" />
                          German (switzerland){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/el"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-EL" />
                          Greek{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/he"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-HE" />
                          Hebrew{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/hi"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-HI" />
                          Hindi{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/hu"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-HU" />
                          Hungarian{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/is"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-IS" />
                          Icelandic{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/id"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ID" />
                          Indonesian{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ga"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-GA" />
                          Irish{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/it"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-IT" />
                          Italian (standard){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/it-ch"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-IT-CH" />
                          Italian (switzerland){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ja"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-JA" />
                          Japanese{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ko"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-KO" />
                          Korean{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ko"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-KO" />
                          Korean (johab){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ku"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-KU" />
                          Kurdish{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/lv"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-LV" />
                          Latvian{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/lt"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-LT" />
                          Lithuanian{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/mk"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-MK" />
                          Macedonian (fyrom){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ml"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ML" />
                          Malayalam{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ms"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-MS" />
                          Malaysian{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/mt"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-MT" />
                          Maltese{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/sr-me"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-SR-ME" />
                          Montenegrin{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/no"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-NO" />
                          Norwegian{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/nb"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-NB" />
                          Norwegian (bokmål){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/nn"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-NN" />
                          Norwegian (nynorsk){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/pl"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-PL" />
                          Polish{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/pt-br"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-PT-BR" />
                          Portuguese (brazil){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/pt"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-PT" />
                          Portuguese (portugal){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/pa"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-PA" />
                          Punjabi{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/rm"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-RM" />
                          Rhaeto-romanic{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ro"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-RO" />
                          Romanian{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ro-md"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-RO-MD" />
                          Romanian (republic of moldova){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ru"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-RU" />
                          Russian{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ru-md"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-RU-MD" />
                          Russian (republic of moldova){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/sr"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-SR" />
                          Serbian{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/sk"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-SK" />
                          Slovak{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/sl"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-SL" />
                          Slovenian{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/sb"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-SB" />
                          Sorbian{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/es-ar"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ES-AR" />
                          Spanish (argentina){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/es-bo"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ES-BO" />
                          Spanish (bolivia){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/es-cl"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ES-CL" />
                          Spanish (chile){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/es-co"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ES-CO" />
                          Spanish (colombia){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/es-cr"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ES-CR" />
                          Spanish (costa rica){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/es-do"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ES-DO" />
                          Spanish (dominican republic){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/es-ec"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ES-EC" />
                          Spanish (ecuador){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/es-sv"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ES-SV" />
                          Spanish (el salvador){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/es-gt"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ES-GT" />
                          Spanish (guatemala){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/es-hn"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ES-HN" />
                          Spanish (honduras){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/es-mx"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ES-MX" />
                          Spanish (mexico){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/es-ni"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ES-NI" />
                          Spanish (nicaragua){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/es-pa"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ES-PA" />
                          Spanish (panama){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/es-py"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ES-PY" />
                          Spanish (paraguay){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/es-pe"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ES-PE" />
                          Spanish (peru){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/es-pr"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ES-PR" />
                          Spanish (puerto rico){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/es"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ES" />
                          Spanish (spain){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/es-uy"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ES-UY" />
                          Spanish (uruguay){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/es-ve"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ES-VE" />
                          Spanish (venezuela){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/sw"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-SW" />
                          Swahili{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/sv"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-SV" />
                          Swedish{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/sv-fi"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-SV-FI" />
                          Swedish (finland){" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/th"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-TH" />
                          Thai{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ts"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-TS" />
                          Tsonga{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/tn"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-TN" />
                          Tswana{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/tr"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-TR" />
                          Turkish{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/uk"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-UK" />
                          Ukrainian{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ur"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-UR" />
                          Urdu{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ve"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-VE" />
                          Venda{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/vi"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-VI" />
                          Vietnamese{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/cy"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-CY" />
                          Welsh{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/xh"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-XH" />
                          Xhosa{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/ji"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-JI" />
                          Yiddish{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/is/change-locale/zu"
                          className="dropdown-item lang-dropdown"
                          role="menuitem"
                        >
                          {" "}
                          <i className="glyphicon bfh-flag-ZU" />
                          Zulu{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>

        {/*Sign In Modal */}
        <div
          className="modal fade"
          id="signInModal"
          tabindex="-1"
          aria-labelledby="signInModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="signInModalLabel">
                  <i className="bi bi-box-arrow-in-right" /> Sign In
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="text-center">Get OTP Login + Signup Popup</div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </React.Fragment>
  )
}
export default Preview

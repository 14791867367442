import * as React from "react"
import PrivateRoute from "../../../components/privateRoute"
import Preview from "../../../components/Drive/Doc/preview"
import ReduxWrapper from "../../../redux/reduxWrapper"

const PreviewPage = props => {
  return (
    <PrivateRoute
      component={Preview}
      location={props.location}
      userName={props.username}
      fileUrl={props.fileUrl}
    />
  )
}

const WrappedPage = props => <ReduxWrapper element={<PreviewPage {...props} />} />
export default WrappedPage

export const PRIVACY_PRIVATE = "private"
export const PRIVACY_PUBLIC = "public"
export const HOME_PAGE = "/home"
export const SUPPORT_URL = "https://swiftcrm.com/support/"
export const RESOURCES_URL = "https://swiftcrm.com/support/resources"
export const MY_FORM_URL = "https://swiftcrm.com/form/my-forms"
export const CREATE_NEW_WEB_FORM_URL = "https://swiftcrm.com/form/create-form"
export const BRANDING_DOC_IMAGE = `${process.env.GATSBY_WRANGLER_URL}StaticImages/SwiftCloud-logo.png`
export const FAVICON_ICON = `${process.env.GATSBY_WRANGLER_URL}StaticImages/favicon.png`
export const BRANDING_COLOR = "#f7f7f7"

export const DEFAULT_CONTACT_PHONE_OPTION = {
  label: "Mobile",
  type: "phone_mobile",
  icon: "bi bi-phone-fill",
  contactApi: "/contact/phone/add/",
  placeHolder: "+1 (999) 999-9999",
}

export const CONTACT_PHONE_OPTIONS = [
  DEFAULT_CONTACT_PHONE_OPTION,
  {
    label: "Work",
    type: "phone_work",
    icon: "bi bi-telephone-fill",
    contactApi: "/contact/phone/add/",
    placeHolder: "+1 (999) 999-9999",
  },
  {
    label: "Phone",
    type: "phone_main",
    icon: "bi bi-telephone-fill",
    contactApi: "/contact/phone/add/",
    placeHolder: "+1 (999) 999-9999",
  },
  {
    label: "Personal",
    type: "phone_personal",
    icon: "bi bi-telephone-fill",
    contactApi: "/contact/phone/add/",
    placeHolder: "+1 (999) 999-9999",
  },
  {
    label: "Business",
    type: "phone_business",
    icon: "bi bi-telephone-fill",
    contactApi: "/contact/phone/add/",
    placeHolder: "+1 (999) 999-9999",
  },
  {
    label: "iPhone",
    type: "phone_iphone",
    icon: "bi bi-phone-fill",
    contactApi: "/contact/phone/add/",
    placeHolder: "+1 (999) 999-9999",
  },
  {
    label: "Fax",
    type: "phone_fax",
    icon: "bi bi-printer",
    contactApi: "/contact/phone/add/",
    placeHolder: "+1 (999) 999-9999",
  },
]

export const DEFAULT_CONTACT_EMAIL_OPTION = {
  label: "Primary",
  type: "email_primary",
  icon: "bi bi-envelope-fill",
  contactApi: "/contact/email/add/",
  placeHolder: "youremail@domain.com",
}
export const CONTACT_EMAIL_OPTIONS = [
  DEFAULT_CONTACT_EMAIL_OPTION,
  {
    label: "Work",
    type: "email_work",
    icon: "bi bi-envelope-fill",
    contactApi: "/contact/email/add/",
    placeHolder: "youremail@domain.com",
  },
  {
    label: "Personal",
    type: "email_personal",
    icon: "bi bi-envelope-fill",
    contactApi: "/contact/email/add/",
    placeHolder: "youremail@domain.com",
  },
  {
    label: "PayPal",
    type: "email_paypal",
    icon: "bi bi-envelope-fill",
    contactApi: "/contact/email/add/",
    placeHolder: "youremail@domain.com",
  },
]

export const DEFAULT_CONTACT_ADDRESS_OPTION = {
  label: "Home",
  type: "home",
  icon: "bi bi-house-door-fill",
  contactApi: "/contact/address/add/",
  placeHolder: "Address",
}
export const CONTACT_ADDRESS_OPTIONS = [
  DEFAULT_CONTACT_ADDRESS_OPTION,
  {
    label: "Mailing",
    type: "mailing",
    icon: "bi bi-house-door-fill",
    contactApi: "/contact/address/add/",
    placeHolder: "Address",
  },
  {
    label: "Billing",
    type: "billing",
    icon: "bi bi-house-door-fill",
    contactApi: "/contact/address/add/",
    placeHolder: "Address",
  },
  {
    label: "Work",
    type: "work",
    icon: "bi bi-house-door-fill",
    contactApi: "/contact/address/add/",
    placeHolder: "Address",
  },
  {
    label: "Other",
    type: "other_address",
    icon: "bi bi-house-door-fill",
    contactApi: "/contact/address/add/",
    placeHolder: "Address",
  },
]

export const DEFAULT_CONTACT_MESSAGE_OPTION = {
  label: "Skype",
  type: "skype",
  icon: "bi bi-skype",
  contactApi: "/contact/meta-data/add/",
  placeHolder: "",
}
export const CONTACT_MESSAGE_OPTIONS = [
  DEFAULT_CONTACT_MESSAGE_OPTION,
  {
    label: "Google Chat",
    type: "google_chat",
    icon: "bi bi-google",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "",
  },
  {
    label: "Telegram",
    type: "telegram",
    icon: "bi bi-telegram",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "",
  },
  {
    label: "Whatsapp",
    type: "whatsapp",
    icon: "bi bi-whatsapp",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "",
  },
]

export const DEFAULT_CONTACT_CALENDAR_OPTION = {
  label: "Birthday",
  type: "birthday",
  icon: "bi bi-calendar-date-fill",
  contactApi: "/contact/meta-data/add/",
  placeHolder: "",
}
export const CONTACT_CALENDAR_OPTIONS = [
  DEFAULT_CONTACT_CALENDAR_OPTION,
  {
    label: "Anniversary",
    type: "anniversary",
    icon: "bi bi-bell-fill",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "",
  },
  {
    label: "Other Date",
    type: "other_date",
    icon: "bi bi-bell-fill",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "",
  },
]

export const DEFAULT_CONTACT_EMPLOYMENT_OPTION = {
  label: "Contact at Company",
  type: "contact_at_company",
  icon: "bi bi-person-fill",
  contactApi: "/contact/meta-data/add/",
  placeHolder: "",
}
export const CONTACT_EMPLOYMENT_OPTIONS = [
  DEFAULT_CONTACT_EMPLOYMENT_OPTION,
  {
    label: "Link to CV / Resume",
    type: "link_cv_resume",
    icon: "bi bi-file-earmark-pdf-fill",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "",
  },
  {
    label: "Former Employer URL",
    type: "former_employer_url",
    icon: "bi bi-link-45deg",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "",
  },
  {
    label: "Portfolio / Work Example",
    type: "portfolio_work_example",
    icon: "bi bi-briefcase-fill",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "",
  },
  {
    label: "Company Name",
    type: "company_name",
    icon: "bi bi-building-fill",
    contactApi: "/contact/update-company-name/",
    placeHolder: "",
  },
  {
    label: "Job Title",
    type: "job_title",
    icon: "bi bi-mortarboard-fill",
    contactApi: "/contact/update-job-title/",
    placeHolder: "",
  },
  {
    label: "Job Role",
    type: "job_role",
    icon: "bi bi-building",
    contactApi: "/contact/update-job-role/",
    placeHolder: "",
  },
]

export const DEFAULT_CONTACT_SOCIAL_LINK_OPTION = {
  label: "Facebook",
  type: "facebook",
  icon: "bi bi-facebook",
  contactApi: "/contact/meta-data/add/",
  placeHolder: "https://",
}
export const CONTACT_SOCIAL_LINK_OPTIONS = [
  DEFAULT_CONTACT_SOCIAL_LINK_OPTION,
  {
    label: "Instagram",
    type: "instagram",
    icon: "bi bi-instagram",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "https://",
  },
  {
    label: "LinkedIn",
    type: "linkedin",
    icon: "bi bi-linkedin",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "https://",
  },
  {
    label: "X (Twitter)",
    type: "twitter",
    icon: "bi bi-twitter-x",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "https://",
  },
  {
    label: "Google",
    type: "google",
    icon: "bi bi-google",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "https://",
  },
  {
    label: "YouTube",
    type: "youtube",
    icon: "bi bi-youtube",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "https://",
  },
  {
    label: "Tiktok",
    type: "tiktok",
    icon: "bi bi-tiktok",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "https://",
  },
  {
    label: "Reddit",
    type: "reddit",
    icon: "bi bi-reddit",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "https://",
  },
  {
    label: "Website Personal",
    type: "website_personal",
    icon: "bi bi-link",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "https://",
  },
  {
    label: "Website (Work)",
    type: "website_work",
    icon: "bi bi-link",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "https://",
  },
  {
    label: "Scheduler",
    type: "swift_cloud_mapped_url",
    icon: "bi bi-calendar3",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "https://",
  },
  {
    label: "RSS (for Follower's Feed)",
    type: "rss_feed",
    icon: "bi bi-rss-fill",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "https://",
  },
  {
    label: "Other URL",
    type: "other_url",
    icon: "bi bi-link-45deg",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "https://",
  },
]

export const CONTACT_RELATIONSHIP_OPTIONS = [
  {
    label: "Referred By",
    type: "referred_by",
    icon: "bi bi-person-fill",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "",
  },
  {
    label: "Referred us to",
    type: "referred_us_to",
    icon: "bi bi-person-fill",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "",
  },
  {
    label: "Spouse",
    type: "spouse",
    icon: "bi bi-person-fill",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "",
  },
  {
    label: "Business Partner",
    type: "business_partner",
    icon: "bi bi-person-fill",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "",
  },
  {
    label: "Parent",
    type: "parent",
    icon: "bi bi-people-fill",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "",
  },
  {
    label: "Child",
    type: "child",
    icon: "bi bi-people-fill",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "",
  },
  {
    label: "Other Relationship",
    type: "other_relationship",
    icon: "bi bi-person-fill",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "",
  },
]
export const CONTACT_PUBLIC_PROFILE_OPTIONS = [
  {
    label: "Category (Primary)",
    type: "category_primary",
    icon: "bi bi-building-fill",
    contactApi: "/contact/update-primary-category/",
    placeHolder: "",
  },
  {
    label: "Category (Additional)",
    type: "category_additional",
    icon: "bi bi-building-fill",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "",
  },
  {
    label: "Founded",
    type: "founded",
    icon: "bi bi-person-fill",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "",
  },
  {
    label: "Meeting Clients",
    type: "meeting_clients",
    icon: "bi bi-calendar3",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "",
  },
  {
    label: "Service Radius (Miles)",
    type: "service_radius_miles",
    icon: "bi bi-person-fill",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "",
  },
  {
    label: "Service Radius (km)",
    type: "service_radius_km",
    icon: "bi bi-person-fill",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "",
  },
  {
    label: "License Number",
    type: "license_number",
    icon: "bi bi-person-fill",
    contactApi: "/contact/meta-data/add/",
    placeHolder: "",
  },
]

export const CONTACT_OPT_IN_TYPE = {
  label: "Opt-In Ip",
  type: "opt_in_ip",
  icon: "bi bi-arrow-up-short",
  contactApi: "/contact/meta-data/add/",
  placeHolder: "",
}

export const CONTACT_META_FIELD_OPTIONS = [
  {
    label: "== PHONES ==",
    type: "",
    icon: "",
    // icon: "bi bi-phone-fill",
    contactApi: "",
    placeHolder: "",
  },
  ...CONTACT_PHONE_OPTIONS,

  {
    label: "== EMAILS ==",
    type: "",
    icon: "",
    // icon: "bi bi-envelope-fill",
    contactApi: "",
    placeHolder: "",
  },
  ...CONTACT_EMAIL_OPTIONS,

  {
    label: "== ADDRESSES ==",
    type: "",
    icon: "",
    // icon: "bi bi-house-door-fill",
    contactApi: "",
    placeHolder: "",
  },
  ...CONTACT_ADDRESS_OPTIONS,

  {
    label: "== MESSENGERS ==",
    type: "",
    icon: "",
    contactApi: "",
    placeHolder: "",
  },
  ...CONTACT_MESSAGE_OPTIONS,

  {
    label: "== CALENDAR ==",
    type: "",
    icon: "",
    contactApi: "",
    placeHolder: "",
  },
  ...CONTACT_CALENDAR_OPTIONS,

  {
    label: "== EMPLOYMENT ==",
    type: "",
    icon: "",
    contactApi: "",
    placeHolder: "",
  },
  ...CONTACT_EMPLOYMENT_OPTIONS,

  {
    label: "== LINKS ==",
    type: "",
    icon: "",
    // icon: "bi bi-box-arrow-up-right",
    contactApi: "",
    placeHolder: "",
  },
  ...CONTACT_SOCIAL_LINK_OPTIONS,

  {
    label: "== RELATIONSHIPS ==",
    type: "",
    icon: "",
    contactApi: "",
    placeHolder: "",
  },
  ...CONTACT_RELATIONSHIP_OPTIONS,
  {
    label: "== PUBLIC PROFILE ==",
    type: "",
    icon: "",
    contactApi: "",
    placeHolder: "",
  },
  ...CONTACT_PUBLIC_PROFILE_OPTIONS,
  CONTACT_OPT_IN_TYPE,
]

export const DEFAULT_TASK_EMAIL_OPTION = {
  label: "Home",
  type: "email_home",
  icon: "bi bi-envelope-fill",
  contactApi: "/contact/email/add/",
  placeHolder: "youremail@domain.com",
}
export const TASK_EMAIL_OPTIONS = [
  {
    label: "Primary",
    type: "email_primary",
    icon: "bi bi-envelope-fill",
    contactApi: "/contact/email/add/",
    placeHolder: "youremail@domain.com",
  },
  {
    label: "Personal",
    type: "email_personal",
    icon: "bi bi-envelope-fill",
    contactApi: "/contact/email/add/",
    placeHolder: "youremail@domain.com",
  },
  {
    label: "Work",
    type: "email_work",
    icon: "bi bi-envelope-fill",
    contactApi: "/contact/email/add/",
    placeHolder: "youremail@domain.com",
  },
  {
    label: "Other",
    type: "email_other",
    icon: "bi bi-envelope-fill",
    contactApi: "/contact/email/add/",
    placeHolder: "youremail@domain.com",
  },
]

export const CONTACT_META_DROPDOWN_FIELDS = ["category_primary", "category_additional"]

export const CONTACT_META_DATE_FIELDS = ["birthday", "anniversary", "other_date"]

export const CONTACT_META_TEXTAREA_FIELDS = ["home", "mailing", "billing", "work", "other"]

export const CONTACT_META_TEXT_FIELDS = ["skype", "google_chat", "telegram", "whatsapp", "opt_in_ip"]

export const CONTACT_META_SOCIAL_LINK_FIELDS = [
  "facebook",
  "instagram",
  "linkedin",
  "twitter",
  "google",
  "youtube",
  "tiktok",
  "reddit",
  "website_personal",
  "website_work",
  "swift_cloud_mapped_url",
  "rss_feed",
  "other_url",
]

export const getTypeData = (options, type) => {
  return options.find(option => {
    return type === option.type
  })
}

import Axios from "axios"
import { navigate } from "gatsby"

import { getAccessToken, getSwitchUserEmail } from "./auth"

const showLoader = () => {
  if (document.getElementsByClassName("leftNavSent").length > 0) {
    const allClassElements = document.getElementsByClassName("leftNavSent")
    for (let i = 0; i < allClassElements.length; i++) {
      allClassElements[i].classList.remove("d-none")
    }
  }
}

const hideLoader = () => {
  if (document.getElementsByClassName("leftNavSent").length > 0) {
    const allClassElements = document.getElementsByClassName("leftNavSent")
    for (let i = 0; i < allClassElements.length; i++) {
      allClassElements[i].classList.add("d-none")
    }
  }
}

const AxiosInstance = Axios.create({
  baseURL: process.env.GATSBY_BACKEND_API_URL,
})

AxiosInstance.interceptors.request.use(
  config => {
    showLoader()

    const token = getAccessToken()
    if (token) {
      config.headers["Authorization"] = ` Bearer ${token}`
    }
    const switchUserEmail = getSwitchUserEmail()
    if (switchUserEmail) {
      config.headers["x-switch-user"] = switchUserEmail
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

const axiosInterceptor = AxiosInstance.interceptors.response.use(
  res => {
    hideLoader()

    return res
  },
  async err => {
    hideLoader()

    const originalConfig = err.config

    if (err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true

        Axios.interceptors.response.eject(axiosInterceptor)

        if (err.config.url.includes("/token")) {
          window.localStorage.setItem(
            "securityMsg",
            "For security reasons you are logged out, Please login again!"
          )
          navigate("/login")
        }

        try {
          const rs = await getRefreshTokenRequest()

          const { access_token, refresh_token } = rs
          window.localStorage.setItem("jwt", access_token)
          window.localStorage.setItem("refreshJwt", refresh_token)

          return AxiosInstance({
            ...originalConfig,
            headers: { ...originalConfig.headers, Authorization: `Bearer ${access_token}` },
            sent: true,
          })
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data)
          }

          return Promise.reject(_error)
        }
      }
    }

    return Promise.reject(err)
  }
)

let refreshTokenRequest = null
const resetAuthTokenRequest = () => {
  refreshTokenRequest = null
}

const getRefreshTokenRequest = () => {
  if (!refreshTokenRequest) {
    refreshTokenRequest = refreshToken()
    refreshTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest)
  }

  return refreshTokenRequest
}

const refreshToken = async () => {
  try {
    let formData = new FormData()
    formData.append("grant_type", process.env.GATSBY_USER_GRANT_TYPE_TOKEN)
    formData.append("scope", process.env.GATSBY_USER_SCOPE)
    formData.append("client_id", process.env.GATSBY_USER_CLIENT_ID)
    formData.append("client_secret", process.env.GATSBY_USER_CLIENT_SECRET)
    formData.append("refresh_token", window.localStorage.getItem("refreshJwt"))

    const resp = await AxiosInstance.post(`${process.env.GATSBY_API_URL}/token`, formData)

    return resp.data
  } catch (e) {
    console.log("Error", e)
  }
}

export default AxiosInstance
